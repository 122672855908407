.dropdown-toggler::after{
background-image: none;


}
.header-dropdown-main{
  transform: translate3d(0px, 0px, 0px);
}

.header-dropdown-menu{

  /* padding: 20px; */
  width: 500px;
  /* display: block; */
  width: max-content;
  text-align: center;
  color: black!important ;
  background: #fff!important;
  border-radius: 20px;
  border: 0.1px solid #fff;
  letter-spacing: normal;
  outline: 0;
  font-weight: 800;
  text-decoration: none;
  padding: 8px 20px;
  font-size: 14px;
  

  cursor: pointer;
  box-shadow: 2px 2px 15px 5px rgba(230, 230, 230, 0.35);
  /* position:relative */
  transition: all 0.3s ease;
}
#header-dropdown-item:hover{
    box-shadow: 2px 2px 2px 2px rgba(121, 78, 78, 0.162) ;
    transition: all 0.3s ease;
    
  }

#header-dropdown-item{
    background-color:#fff;
    color: black;
    margin: 10px;
    border-radius: 1px;
    border-bottom: 0.24px solid #151515;
    
}


.header-input{

    background-color: white;
    width: 100%;

    display: flex;
    border: 0.5px solid black;
}
.colorize{
    
    text-align: left;
    margin-top: 2px;
    color: black;
}
.colorize a {
  display: flex;
  line-height: 20px;

}
.colorize a svg{

max-width: fit-content;
  line-height: 20px;

}
.colorize a span {

  background: none;
  color: #151515;

}